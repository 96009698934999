import { Logout } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Container,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  dividerClasses,
} from "@mui/material";
import React from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { NavLink, useHistory } from "react-router-dom";
import Basket from "./basket";
import { verifiedMemberData } from "../../apiServices/verify";

export function NavbarHome(props: any) {
  const history = useHistory()
  const goCarsHandler = () => {
    
    history.push("/dealer/cars");
    window.scrollTo(0,500)
  };
  return (
    <div className="format home_navbar">
      <Container>
        <Stack
          flexDirection={"row"}
          className="navbar_config"
          justifyContent={"space-between"}
        >
          <Box className="border_icons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="81"
              height="20"
              viewBox="0 0 122 27"
              fill="none"
              className="angel_image"
            >
              <path
                d="M0.386719 26.0249C1.59545 23.3363 6.94625 13.3539 20.182 11.8816C23.0853 11.5578 25.7851 11.4109 28.6808 11.4109C31.456 11.4109 34.0429 11.5503 36.7804 11.6971C39.8343 11.8628 42.9935 12.0322 46.7704 12.0322C52.5241 12.0322 58.4623 11.6331 65.3381 10.7783C61.599 11.667 51.2927 13.7945 38.3281 13.7945C35.7412 13.7945 33.1467 13.7079 30.6201 13.5422C29.7728 13.4857 28.9143 13.4556 28.067 13.4556C14.9329 13.4518 5.07855 20.0942 0.386719 26.0249Z"
                fill="#F02E2E"
              />
              <path
                d="M63.5092 11.0867C58.8324 12.0883 49.5768 13.6962 38.3104 13.6962C35.7234 13.6962 33.1328 13.6096 30.6099 13.4439C29.7589 13.3874 28.9003 13.3573 28.0531 13.3573C15.3106 13.3573 5.64827 19.593 0.771931 25.3806C1.40077 24.1041 2.56808 22.0707 4.42448 19.9809C7.23356 16.8141 12.2455 12.8565 20.1756 11.9754C23.0751 11.6515 25.7712 11.5047 28.6631 11.5047C31.4345 11.5047 34.0214 11.644 36.759 11.7909C39.8128 11.9565 42.9758 12.126 46.7526 12.126C51.9942 12.1222 57.3902 11.7909 63.5092 11.0867ZM66.8605 10.4805C58.2902 11.6064 51.9604 11.934 46.7526 11.934C39.5718 11.934 34.5185 11.3126 28.6631 11.3126C26.0687 11.3126 23.3161 11.4331 20.153 11.7871C4.66924 13.5079 0 26.6533 0 26.6533C4.95918 19.9658 15.2767 13.5456 28.0493 13.5456C28.889 13.5456 29.7363 13.572 30.5948 13.6284C33.2495 13.8054 35.8289 13.8807 38.3104 13.8807C54.8184 13.8845 66.8605 10.4805 66.8605 10.4805Z"
                fill="#F02E2E"
              />
              <path
                d="M32.4297 10.0668C32.4297 10.0668 53.7877 -1.25235 67.8782 0.114532C88.1518 2.08013 99.0266 7.97692 116.107 8.22545C116.107 8.22545 106.893 9.57727 88.4606 6.62887C64.2069 2.74663 61.0589 -0.273316 32.4297 10.0668Z"
                fill="white"
              />
              <path
                d="M43.8848 15.0458C43.8848 15.0458 55.283 14.6316 68.9706 10.9678C82.7825 7.27003 85.5427 6.78051 93.454 8.52395C93.454 8.52395 85.9192 8.3432 73.5759 11.864C61.2325 15.3847 54.2399 16.2131 43.8848 15.0458Z"
                fill="white"
                stroke="white"
                stroke-width="0.75"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M114.815 26.0992C115.861 24.849 117.887 21.8931 116.746 18.8694C114.882 13.9404 105.83 11.0522 89.8379 10.2803C91.5324 10.1184 94.8234 9.8623 98.7772 9.8623C105.732 9.8623 115.383 10.6568 121.909 14.4449C121.668 15.2395 119.363 22.6462 114.815 26.0992Z"
                fill="#F02E2E"
              />
            </svg>
          </Box>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            className="navbar_links"
          >
            <Box className="hover-line" onClick={props.setPath}>
              <NavLink to={"/"} activeClassName="underline">
                Home
              </NavLink>
            </Box>
            <Box className="hover-line" onClick={props.setPath}>
              <NavLink to={"/dealer"} activeClassName="underline">
                Dealers
              </NavLink>
            </Box>
            {verifiedMemberData ? (
              <Box className="hover-line" onClick={props.setPath}>
                <NavLink to={"/orders"} activeClassName="underline">
                  Order
                </NavLink>
              </Box>
            ) : null}

            <Box className="hover-line" onClick={props.setPath}>
              <NavLink to={"/community"} activeClassName="underline">
                Community
              </NavLink>
            </Box>
            {verifiedMemberData ? (
              <Box className="hover-line" onClick={props.setPath}>
                <NavLink to="/member-page" activeClassName="underline">
                  My Page
                </NavLink>
              </Box>
            ) : null}
            <Box className="hover-line" onClick={props.setPath}>
              <NavLink to={"/help"} activeClassName="underline">
                Contact us
              </NavLink>
            </Box>
            <Box className="hover-line">
              <IconButton color="secondary" aria-label="add to shopping cart">
                <Basket
                  cartItems={props.cartItems}
                  onAdd={props.onAdd}
                  onDelete={props.onDelete}
                  onDeleteAll={props.onDeleteAll}
                  setOrderRebuild={props.setOrderRebuild}
                />
              </IconButton>
            </Box>
            {!verifiedMemberData ? (
              <Box>
                <Button
                  variant="contained"
                  color="success"
                  onClick={props.handleLoginOpen}
                >
                  Login
                </Button>
              </Box>
            ) : (
              <img
                style={{ width: "48px", height: "48px", borderRadius: "24px" }}
                src={verifiedMemberData.mb_image}
                onClick={props.handleLogOutClick}
              />
            )}
            <Menu
              anchorEl={props.anchorEl}
              open={props.open}
              onClose={props.handleCloseLogOut}
              onClick={props.handleCloseLogOut}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={props.handleLogOutRequest}>
                <ListItemIcon>
                  <Logout fontSize="small" style={{ color: "blue" }} />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
            {!verifiedMemberData ? (
              <Box>
                <Button
                  variant="contained"
                  color="success"
                  onClick={props.handleSignUpOpen}
                >
                  Register
                </Button>
              </Box>
            ) : null}
          </Stack>
        </Stack>

        <Stack className="head_information" justifyContent={"row"}>
          <Box className="site_name">
            <span className="name_deco">PROCAR.UZ</span>
          </Box>
          <Box className="center_word_1">
            <div className="center_word_div">
              <span className="center_word_inner1">Exceptional</span>
            </div>
            <div className="center_word_div" style={{ marginLeft: "10px" }}>
              <span className="center_word_inner2">Cars</span>
            </div>
          </Box>
          <Box className="center_word_1" style={{ marginLeft: "210px" }}>
            <div className="center_word_div">
              <span className="center_word_inner2">Exceptional</span>
            </div>
            <div className="center_word_div" style={{ marginLeft: "10px" }}>
              <span className="center_word_inner1">Drivers</span>
            </div>
          </Box>
          <div className="header_shop" onClick={goCarsHandler}>Shop Now</div>
          <Box className="site_desc">
            <span className="site_desc_text">
              Who wants to buy new cars, this is the best place for them.There
              are many types of cars in our store.Come to our store and find
              your dream
            </span>
          </Box>
          <Box className="bottom_frame">
            <div className="frame_div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="150"
                height="150"
                viewBox="0 0 150 150"
                fill="none"
              >
                <circle
                  cx="75"
                  cy="75"
                  r="73.5"
                  stroke="#D9D5C8"
                  stroke-opacity="0.4"
                  stroke-width="3"
                />
                <text className="svg_text" fill="white" x="45" y="70">
                  QUALITY
                </text>
                <mask id="path-1-inside-1_1_17" fill="white">
                  <path d="M148.5 75C149.328 75 150.002 75.6717 149.985 76.4999C149.679 91.811 144.692 106.675 135.676 119.084C126.367 131.897 113.24 141.435 98.1763 146.329C83.113 151.224 66.887 151.224 51.8237 146.329C36.7605 141.435 23.6333 131.897 14.3237 119.084C5.01412 106.27 -1.38464e-06 90.8384 0 75C1.38464e-06 59.1615 5.01412 43.7297 14.3237 30.9161C23.6333 18.1025 36.7605 8.56511 51.8237 3.67076C66.4111 -1.06895 82.0889 -1.2187 96.7451 3.22152C97.538 3.46172 97.9688 4.30947 97.7128 5.09735C97.4568 5.88523 96.6109 6.31481 95.8179 6.07527C81.7644 1.83045 66.7356 1.98 52.7508 6.52393C38.2901 11.2225 25.688 20.3784 16.7508 32.6795C7.81356 44.9805 3 59.7951 3 75C3 90.2049 7.81355 105.02 16.7508 117.321C25.688 129.622 38.29 138.777 52.7508 143.476C67.2115 148.175 82.7885 148.175 97.2492 143.476C111.71 138.777 124.312 129.622 133.249 117.321C141.892 105.424 146.679 91.1774 146.984 76.5C147.002 75.6717 147.672 75 148.5 75Z" />
                </mask>
                <path
                  d="M148.5 75C149.328 75 150.002 75.6717 149.985 76.4999C149.679 91.811 144.692 106.675 135.676 119.084C126.367 131.897 113.24 141.435 98.1763 146.329C83.113 151.224 66.887 151.224 51.8237 146.329C36.7605 141.435 23.6333 131.897 14.3237 119.084C5.01412 106.27 -1.38464e-06 90.8384 0 75C1.38464e-06 59.1615 5.01412 43.7297 14.3237 30.9161C23.6333 18.1025 36.7605 8.56511 51.8237 3.67076C66.4111 -1.06895 82.0889 -1.2187 96.7451 3.22152C97.538 3.46172 97.9688 4.30947 97.7128 5.09735C97.4568 5.88523 96.6109 6.31481 95.8179 6.07527C81.7644 1.83045 66.7356 1.98 52.7508 6.52393C38.2901 11.2225 25.688 20.3784 16.7508 32.6795C7.81356 44.9805 3 59.7951 3 75C3 90.2049 7.81355 105.02 16.7508 117.321C25.688 129.622 38.29 138.777 52.7508 143.476C67.2115 148.175 82.7885 148.175 97.2492 143.476C111.71 138.777 124.312 129.622 133.249 117.321C141.892 105.424 146.679 91.1774 146.984 76.5C147.002 75.6717 147.672 75 148.5 75Z"
                  stroke="#FFC600"
                  stroke-width="10"
                  mask="url(#path-1-inside-1_1_17)"
                />
              </svg>
            </div>
            <div className="frame_div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="150"
                height="150"
                viewBox="0 0 150 150"
                fill="none"
              >
                <circle
                  cx="75"
                  cy="75"
                  r="73.5"
                  stroke="#D9D5C8"
                  stroke-opacity="0.4"
                  stroke-width="3"
                />
                <text className="svg_text" fill="white" x="45" y="70">
                  SPEED
                </text>
                <mask id="path-1-inside-1_1_17" fill="white">
                  <path d="M148.5 75C149.328 75 150.002 75.6717 149.985 76.4999C149.679 91.811 144.692 106.675 135.676 119.084C126.367 131.897 113.24 141.435 98.1763 146.329C83.113 151.224 66.887 151.224 51.8237 146.329C36.7605 141.435 23.6333 131.897 14.3237 119.084C5.01412 106.27 -1.38464e-06 90.8384 0 75C1.38464e-06 59.1615 5.01412 43.7297 14.3237 30.9161C23.6333 18.1025 36.7605 8.56511 51.8237 3.67076C66.4111 -1.06895 82.0889 -1.2187 96.7451 3.22152C97.538 3.46172 97.9688 4.30947 97.7128 5.09735C97.4568 5.88523 96.6109 6.31481 95.8179 6.07527C81.7644 1.83045 66.7356 1.98 52.7508 6.52393C38.2901 11.2225 25.688 20.3784 16.7508 32.6795C7.81356 44.9805 3 59.7951 3 75C3 90.2049 7.81355 105.02 16.7508 117.321C25.688 129.622 38.29 138.777 52.7508 143.476C67.2115 148.175 82.7885 148.175 97.2492 143.476C111.71 138.777 124.312 129.622 133.249 117.321C141.892 105.424 146.679 91.1774 146.984 76.5C147.002 75.6717 147.672 75 148.5 75Z" />
                </mask>
                <path
                  d="M148.5 75C149.328 75 150.002 75.6717 149.985 76.4999C149.679 91.811 144.692 106.675 135.676 119.084C126.367 131.897 113.24 141.435 98.1763 146.329C83.113 151.224 66.887 151.224 51.8237 146.329C36.7605 141.435 23.6333 131.897 14.3237 119.084C5.01412 106.27 -1.38464e-06 90.8384 0 75C1.38464e-06 59.1615 5.01412 43.7297 14.3237 30.9161C23.6333 18.1025 36.7605 8.56511 51.8237 3.67076C66.4111 -1.06895 82.0889 -1.2187 96.7451 3.22152C97.538 3.46172 97.9688 4.30947 97.7128 5.09735C97.4568 5.88523 96.6109 6.31481 95.8179 6.07527C81.7644 1.83045 66.7356 1.98 52.7508 6.52393C38.2901 11.2225 25.688 20.3784 16.7508 32.6795C7.81356 44.9805 3 59.7951 3 75C3 90.2049 7.81355 105.02 16.7508 117.321C25.688 129.622 38.29 138.777 52.7508 143.476C67.2115 148.175 82.7885 148.175 97.2492 143.476C111.71 138.777 124.312 129.622 133.249 117.321C141.892 105.424 146.679 91.1774 146.984 76.5C147.002 75.6717 147.672 75 148.5 75Z"
                  stroke="#FFC600"
                  stroke-width="10"
                  mask="url(#path-1-inside-1_1_17)"
                />
              </svg>
            </div>
            <div className="frame_div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="150"
                height="150"
                viewBox="0 0 150 150"
                fill="none"
              >
                <circle
                  cx="75"
                  cy="75"
                  r="73.5"
                  stroke="#D9D5C8"
                  stroke-opacity="0.4"
                  stroke-width="3"
                />
                <text className="svg_text" fill="white" x="45" y="70">
                  TURBO
                </text>
                <mask id="path-1-inside-1_1_17" fill="white">
                  <path d="M148.5 75C149.328 75 150.002 75.6717 149.985 76.4999C149.679 91.811 144.692 106.675 135.676 119.084C126.367 131.897 113.24 141.435 98.1763 146.329C83.113 151.224 66.887 151.224 51.8237 146.329C36.7605 141.435 23.6333 131.897 14.3237 119.084C5.01412 106.27 -1.38464e-06 90.8384 0 75C1.38464e-06 59.1615 5.01412 43.7297 14.3237 30.9161C23.6333 18.1025 36.7605 8.56511 51.8237 3.67076C66.4111 -1.06895 82.0889 -1.2187 96.7451 3.22152C97.538 3.46172 97.9688 4.30947 97.7128 5.09735C97.4568 5.88523 96.6109 6.31481 95.8179 6.07527C81.7644 1.83045 66.7356 1.98 52.7508 6.52393C38.2901 11.2225 25.688 20.3784 16.7508 32.6795C7.81356 44.9805 3 59.7951 3 75C3 90.2049 7.81355 105.02 16.7508 117.321C25.688 129.622 38.29 138.777 52.7508 143.476C67.2115 148.175 82.7885 148.175 97.2492 143.476C111.71 138.777 124.312 129.622 133.249 117.321C141.892 105.424 146.679 91.1774 146.984 76.5C147.002 75.6717 147.672 75 148.5 75Z" />
                </mask>
                <path
                  d="M148.5 75C149.328 75 150.002 75.6717 149.985 76.4999C149.679 91.811 144.692 106.675 135.676 119.084C126.367 131.897 113.24 141.435 98.1763 146.329C83.113 151.224 66.887 151.224 51.8237 146.329C36.7605 141.435 23.6333 131.897 14.3237 119.084C5.01412 106.27 -1.38464e-06 90.8384 0 75C1.38464e-06 59.1615 5.01412 43.7297 14.3237 30.9161C23.6333 18.1025 36.7605 8.56511 51.8237 3.67076C66.4111 -1.06895 82.0889 -1.2187 96.7451 3.22152C97.538 3.46172 97.9688 4.30947 97.7128 5.09735C97.4568 5.88523 96.6109 6.31481 95.8179 6.07527C81.7644 1.83045 66.7356 1.98 52.7508 6.52393C38.2901 11.2225 25.688 20.3784 16.7508 32.6795C7.81356 44.9805 3 59.7951 3 75C3 90.2049 7.81355 105.02 16.7508 117.321C25.688 129.622 38.29 138.777 52.7508 143.476C67.2115 148.175 82.7885 148.175 97.2492 143.476C111.71 138.777 124.312 129.622 133.249 117.321C141.892 105.424 146.679 91.1774 146.984 76.5C147.002 75.6717 147.672 75 148.5 75Z"
                  stroke="#FFC600"
                  stroke-width="10"
                  mask="url(#path-1-inside-1_1_17)"
                />
              </svg>
            </div>
            <div className="frame_div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="150"
                height="150"
                viewBox="0 0 150 150"
                fill="none"
              >
                <circle
                  cx="75"
                  cy="75"
                  r="73.5"
                  stroke="#D9D5C8"
                  stroke-opacity="0.4"
                  stroke-width="3"
                />
                <text className="svg_text" fill="white" x="45" y="70">
                  POWER
                </text>
                <mask id="path-1-inside-1_1_17" fill="white">
                  <path d="M148.5 75C149.328 75 150.002 75.6717 149.985 76.4999C149.679 91.811 144.692 106.675 135.676 119.084C126.367 131.897 113.24 141.435 98.1763 146.329C83.113 151.224 66.887 151.224 51.8237 146.329C36.7605 141.435 23.6333 131.897 14.3237 119.084C5.01412 106.27 -1.38464e-06 90.8384 0 75C1.38464e-06 59.1615 5.01412 43.7297 14.3237 30.9161C23.6333 18.1025 36.7605 8.56511 51.8237 3.67076C66.4111 -1.06895 82.0889 -1.2187 96.7451 3.22152C97.538 3.46172 97.9688 4.30947 97.7128 5.09735C97.4568 5.88523 96.6109 6.31481 95.8179 6.07527C81.7644 1.83045 66.7356 1.98 52.7508 6.52393C38.2901 11.2225 25.688 20.3784 16.7508 32.6795C7.81356 44.9805 3 59.7951 3 75C3 90.2049 7.81355 105.02 16.7508 117.321C25.688 129.622 38.29 138.777 52.7508 143.476C67.2115 148.175 82.7885 148.175 97.2492 143.476C111.71 138.777 124.312 129.622 133.249 117.321C141.892 105.424 146.679 91.1774 146.984 76.5C147.002 75.6717 147.672 75 148.5 75Z" />
                </mask>
                <path
                  d="M148.5 75C149.328 75 150.002 75.6717 149.985 76.4999C149.679 91.811 144.692 106.675 135.676 119.084C126.367 131.897 113.24 141.435 98.1763 146.329C83.113 151.224 66.887 151.224 51.8237 146.329C36.7605 141.435 23.6333 131.897 14.3237 119.084C5.01412 106.27 -1.38464e-06 90.8384 0 75C1.38464e-06 59.1615 5.01412 43.7297 14.3237 30.9161C23.6333 18.1025 36.7605 8.56511 51.8237 3.67076C66.4111 -1.06895 82.0889 -1.2187 96.7451 3.22152C97.538 3.46172 97.9688 4.30947 97.7128 5.09735C97.4568 5.88523 96.6109 6.31481 95.8179 6.07527C81.7644 1.83045 66.7356 1.98 52.7508 6.52393C38.2901 11.2225 25.688 20.3784 16.7508 32.6795C7.81356 44.9805 3 59.7951 3 75C3 90.2049 7.81355 105.02 16.7508 117.321C25.688 129.622 38.29 138.777 52.7508 143.476C67.2115 148.175 82.7885 148.175 97.2492 143.476C111.71 138.777 124.312 129.622 133.249 117.321C141.892 105.424 146.679 91.1774 146.984 76.5C147.002 75.6717 147.672 75 148.5 75Z"
                  stroke="#FFC600"
                  stroke-width="10"
                  mask="url(#path-1-inside-1_1_17)"
                />
              </svg>
            </div>
            <div className="frame_div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="150"
                height="150"
                viewBox="0 0 150 150"
                fill="none"
              >
                <circle
                  cx="75"
                  cy="75"
                  r="73.5"
                  stroke="#D9D5C8"
                  stroke-opacity="0.4"
                  stroke-width="3"
                />
                <text className="svg_text" fill="white" x="45" y="70">
                  DESIGN
                </text>
                <mask id="path-1-inside-1_1_17" fill="white">
                  <path d="M148.5 75C149.328 75 150.002 75.6717 149.985 76.4999C149.679 91.811 144.692 106.675 135.676 119.084C126.367 131.897 113.24 141.435 98.1763 146.329C83.113 151.224 66.887 151.224 51.8237 146.329C36.7605 141.435 23.6333 131.897 14.3237 119.084C5.01412 106.27 -1.38464e-06 90.8384 0 75C1.38464e-06 59.1615 5.01412 43.7297 14.3237 30.9161C23.6333 18.1025 36.7605 8.56511 51.8237 3.67076C66.4111 -1.06895 82.0889 -1.2187 96.7451 3.22152C97.538 3.46172 97.9688 4.30947 97.7128 5.09735C97.4568 5.88523 96.6109 6.31481 95.8179 6.07527C81.7644 1.83045 66.7356 1.98 52.7508 6.52393C38.2901 11.2225 25.688 20.3784 16.7508 32.6795C7.81356 44.9805 3 59.7951 3 75C3 90.2049 7.81355 105.02 16.7508 117.321C25.688 129.622 38.29 138.777 52.7508 143.476C67.2115 148.175 82.7885 148.175 97.2492 143.476C111.71 138.777 124.312 129.622 133.249 117.321C141.892 105.424 146.679 91.1774 146.984 76.5C147.002 75.6717 147.672 75 148.5 75Z" />
                </mask>
                <path
                  d="M148.5 75C149.328 75 150.002 75.6717 149.985 76.4999C149.679 91.811 144.692 106.675 135.676 119.084C126.367 131.897 113.24 141.435 98.1763 146.329C83.113 151.224 66.887 151.224 51.8237 146.329C36.7605 141.435 23.6333 131.897 14.3237 119.084C5.01412 106.27 -1.38464e-06 90.8384 0 75C1.38464e-06 59.1615 5.01412 43.7297 14.3237 30.9161C23.6333 18.1025 36.7605 8.56511 51.8237 3.67076C66.4111 -1.06895 82.0889 -1.2187 96.7451 3.22152C97.538 3.46172 97.9688 4.30947 97.7128 5.09735C97.4568 5.88523 96.6109 6.31481 95.8179 6.07527C81.7644 1.83045 66.7356 1.98 52.7508 6.52393C38.2901 11.2225 25.688 20.3784 16.7508 32.6795C7.81356 44.9805 3 59.7951 3 75C3 90.2049 7.81355 105.02 16.7508 117.321C25.688 129.622 38.29 138.777 52.7508 143.476C67.2115 148.175 82.7885 148.175 97.2492 143.476C111.71 138.777 124.312 129.622 133.249 117.321C141.892 105.424 146.679 91.1774 146.984 76.5C147.002 75.6717 147.672 75 148.5 75Z"
                  stroke="#FFC600"
                  stroke-width="10"
                  mask="url(#path-1-inside-1_1_17)"
                />
              </svg>
            </div>
          </Box>
        </Stack>
      </Container>
    </div>
  );
}
