import { Box, Container, Stack } from "@mui/material";
import React from "react";
import "../../../css/homepage.css";

export function Statistics() {
  return (
    <div className="static_frame">
      <Container>
        <Stack className="stat_stack">
          <Box
            flexDirection={"row"}
            justifyContent={"flex-start"}
            height={"12px"}
          >
            <p className="head_red_text">Trusted Car Delaer Service</p>
          </Box>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            marginTop={"7px"}
          >
            <h1 className="stat_heading">
              Leading The Best Car Dealers In The World
            </h1>
            <p className="stat_desc">
              There are many variations of simply free text passages of Lorem
              available but the majority have suffered alteration in some form
              by injected hum randomised words which don't slightly.
            </p>
          </Stack>
          <Stack className="stat_bottom">
            <div className="inner_box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="55"
                viewBox="0 0 76 68"
                fill="none"
              >
                <path
                  d="M70.8177 23.8466L69.2522 22.1851L63.7577 7.33891C62.8214 4.83122 60.4271 3.16968 57.7566 3.1543H18.5117C15.8412 3.1543 13.4469 4.83122 12.5106 7.33891L7.09279 22.0004L4.83663 24.0927C2.87208 25.7697 1.73633 28.2312 1.73633 30.8158V52.4466C1.75168 55.985 4.62175 58.8466 8.1518 58.8466H12.2037C15.7337 58.8466 18.6038 55.985 18.6191 52.4466V49.3081H56.6822V52.4466C56.6976 55.985 59.5676 58.8466 63.0977 58.8466H67.1496C70.6796 58.8466 73.5497 55.985 73.565 52.4466V30.2466C73.5497 27.8312 72.5674 25.5235 70.8177 23.8466ZM15.3961 8.38506C15.8872 7.07737 17.1304 6.23122 18.5117 6.23122H57.7719C59.1533 6.23122 60.3964 7.09276 60.8876 8.38506L65.6762 21.3081H62.1001C61.2559 15.5389 55.8995 11.5543 50.144 12.4158C45.5549 13.0928 41.9481 16.7081 41.2728 21.3081H10.6075L15.3961 8.38506ZM58.9691 21.3081H44.3731C45.2173 17.262 49.177 14.6774 53.2136 15.5235C56.099 16.1389 58.3552 18.4004 58.9691 21.3081ZM15.5495 52.4466C15.5342 54.2927 14.0454 55.7696 12.2037 55.7696H8.1518C6.31004 55.7696 4.82128 54.2927 4.80593 52.4466V48.4158C5.72681 49.0158 6.9393 49.3081 8.1518 49.3081H15.5495V52.4466ZM70.4954 52.4466C70.4801 54.2927 68.9913 55.7696 67.1496 55.7696H63.0977C61.2559 55.7696 59.7672 54.2773 59.7518 52.4466V49.3081H67.1496C68.3621 49.3081 69.5746 49.0158 70.4954 48.4158V52.4466ZM67.1496 46.2312H8.1518C6.32538 46.2466 4.82128 44.785 4.80593 42.9389V30.8158C4.80593 29.1235 5.55798 27.5081 6.84722 26.4158C6.86256 26.4004 6.89326 26.3697 6.90861 26.3543L8.99594 24.385H67.1189L68.5923 25.9697C68.6076 26.0004 68.6537 26.0158 68.669 26.0466C69.8201 27.1389 70.4801 28.662 70.4801 30.2466V42.9389H70.4954C70.4801 44.7697 68.976 46.2466 67.1496 46.2312Z"
                  fill="#D01818"
                />
                <path
                  d="M21.995 30.5381H10.484C9.63988 30.5381 8.94922 31.2304 8.94922 32.0765V40.0765C8.94922 40.9227 9.63988 41.615 10.484 41.615H21.995C22.8392 41.615 23.5298 40.9227 23.5298 40.0765V32.0765C23.5298 31.2304 22.8392 30.5381 21.995 30.5381ZM20.4602 38.5381H12.0188V33.615H20.4602V38.5381Z"
                  fill="#D01818"
                />
                <path
                  d="M64.8173 30.5381H53.3063C52.4621 30.5381 51.7715 31.2304 51.7715 32.0765V40.0765C51.7715 40.9227 52.4621 41.615 53.3063 41.615H64.8173C65.6614 41.615 66.3521 40.9227 66.3521 40.0765V32.0765C66.3521 31.2304 65.6614 30.5381 64.8173 30.5381ZM63.2825 38.5381H54.8411V33.615H63.2825V38.5381Z"
                  fill="#D01818"
                />
                <path
                  d="M45.0641 37H30.2379C29.3938 37 28.7031 37.6923 28.7031 38.5385C28.7031 39.3846 29.3938 40.0769 30.2379 40.0769H45.0641C45.9083 40.0769 46.5989 39.3846 46.5989 38.5385C46.5989 37.6923 45.9083 37 45.0641 37Z"
                  fill="#D01818"
                />
                <path
                  d="M45.0641 32.2305H30.2379C29.3938 32.2305 28.7031 32.9228 28.7031 33.7689C28.7031 34.6151 29.3938 35.3074 30.2379 35.3074H45.0641C45.9083 35.3074 46.5989 34.6151 46.5989 33.7689C46.5989 32.9228 45.9083 32.2305 45.0641 32.2305Z"
                  fill="#D01818"
                />
              </svg>
              <div className="inner_box_num">1200</div>
              <div className="inner_box_desc">Vehicle in Stock cars</div>
            </div>
            <div className="inner_box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="55"
                viewBox="0 0 93 74"
                fill="none"
              >
                <path
                  d="M22.5684 46.6988L35.1185 52.0133C35.0961 51.8564 35.0737 51.6994 35.0737 51.5312C35.0737 49.087 37.0535 47.1024 39.492 47.1024H48.5298C48.8989 47.1024 49.2569 47.024 49.5924 46.867L57.2545 43.3912C58.4513 42.853 59.7824 42.5615 61.0911 42.5615H67.3326V44.8039H61.0911C60.0956 44.8039 59.0889 45.017 58.1717 45.4318L50.5096 48.9076C49.8833 49.1879 49.2121 49.3337 48.5186 49.3337H39.4808C38.2728 49.3337 37.2996 50.3203 37.2996 51.52C37.2996 52.7197 38.2839 53.7064 39.4808 53.7064H51.192V55.9488H39.492C38.7202 55.9488 38.0043 55.7358 37.3779 55.3882V55.3994L21.7071 48.7506C20.4096 48.2012 18.989 48.2124 17.7027 48.773C16.7184 49.2103 15.913 49.9167 15.3761 50.8361L43.0378 65.5464C45.1518 66.6676 47.6685 66.8918 49.9504 66.1631L63.4848 61.8015L67.3438 61.7455V63.9879H63.9993L50.6327 68.2934C49.5253 68.6521 48.3844 68.8203 47.2323 68.8203C45.4203 68.8203 43.6082 68.3831 41.9975 67.5197L12.5238 51.8676L12.8482 50.9482C13.5305 49.0421 14.9399 47.5397 16.7967 46.7212C18.6535 45.9252 20.7004 45.9027 22.5684 46.6988Z"
                  fill="#D01818"
                />
                <path
                  d="M69.7589 65.7479V41.9446C69.7589 40.7112 70.7656 39.7021 71.996 39.7021H83.2598V41.9446H71.996V65.7591H83.2598V68.0015H71.996C70.7656 67.9903 69.7589 66.9812 69.7589 65.7479Z"
                  fill="#D01818"
                />
                <path
                  d="M74.937 62.2606C74.937 63.1239 75.6416 63.8303 76.5029 63.8303C77.3754 63.8303 78.0801 63.1239 78.0801 62.2606C78.0801 61.3861 77.3754 60.6797 76.5029 60.6797C75.6416 60.6797 74.937 61.3861 74.937 62.2606Z"
                  fill="#D01818"
                />
                <path
                  d="M56.7947 31.8762L55.5866 32.7732L47.2647 28.1538L39.7368 32.6947L39.0881 33.0759L37.8801 32.2014L39.6921 22.8505L33.0368 17.065L32.4999 16.5493L32.9696 15.159L42.3878 13.9929L45.8105 5.87535L46.1237 5.20262L46.8396 5.19141H47.6002L51.6158 13.8359L60.3852 14.5759L61.1346 14.6656L61.582 16.0672L54.6359 22.559L56.7947 31.8762ZM57.7454 16.6053L50.7881 15.9999L50.1281 15.9214L46.9179 9.01474L44.1998 15.4393L43.9202 16.0559L36.3588 16.9866L41.5936 21.5387L42.1193 21.9872L40.6652 29.4993L46.6606 25.8778L47.2311 25.575L53.8641 29.2638L52.3093 22.5366L52.1191 21.8414L57.7454 16.6053Z"
                  fill="#D01818"
                />
                <path
                  d="M71.5035 27.7388L73.1254 34.724L73.3491 35.5313L72.0852 36.4619L65.1166 32.5937L58.9646 36.2937L58.2711 36.731L57.0296 35.8228L58.5508 27.9855L56.9289 26.584L58.3942 24.891L60.553 26.7634L60.8774 27.1446V27.5931L59.8259 33.1655L64.3672 30.4185L64.6021 30.1943L65.5529 30.1719L65.7766 30.3961L70.4185 32.9749L69.1658 27.5258V26.8643L73.5169 22.8055L68.1926 22.3346L67.6781 22.3234L67.2083 21.8525L64.8258 16.7173L62.6782 21.7964L62.3315 22.4579H61.7051L59.0094 22.7943L58.7297 20.5631L60.8885 20.3052L63.7296 13.5779L64.1099 12.8604L65.6535 12.9837L65.8661 13.6452L68.8861 20.1594L76.0001 20.7761L76.8837 20.821L77.3535 22.4579L76.7383 22.8616L71.5035 27.7388Z"
                  fill="#D01818"
                />
                <path
                  d="M34.7377 20.3053L34.5364 22.5365L32.3776 22.3347L31.8743 22.3122L31.4045 21.8413L29.022 16.7062L26.975 21.5386L26.9527 22.4468H25.9012L20.5658 23.1083L24.7156 26.7186L25.0847 27.111V27.5932L24.0221 33.1544L28.5746 30.4186L28.8095 30.1832L29.7938 30.1944L29.9951 30.4074L34.6147 32.975L33.3507 27.5259L33.3619 26.8644L36.2254 24.1847L37.7578 25.8216L35.7109 27.7389L37.3216 34.7241L37.5453 35.4753L36.3261 36.4844L29.3128 32.5938L23.1496 36.305L22.4561 36.731L21.2369 35.8116L22.7581 27.9856L17.3779 23.3101C17.0088 23.0186 16.8522 22.5253 16.9753 22.0768L17.2325 21.2807L25.0847 20.3165L27.9482 13.5668L28.2837 12.9501L29.6148 12.9053L29.9616 13.4547L33.0935 20.1595L34.7377 20.3053Z"
                  fill="#D01818"
                />
              </svg>
              <div className="inner_box_num">120k</div>
              <div className="inner_box_desc">Satisfied customers</div>
            </div>
            <div className="inner_box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="55"
                viewBox="0 0 89 64"
                fill="none"
              >
                <path
                  d="M46.0986 56.5C45.4102 56.5 44.8516 57.06 44.8516 57.75C44.8516 58.44 45.4102 59 46.0986 59C46.7869 59 47.3456 58.44 47.3456 57.75C47.3456 57.06 46.7869 56.5 46.0986 56.5Z"
                  fill="#D01818"
                />
                <path
                  d="M63.5578 5.75H59.8168V3.75C59.8168 1.68225 58.1385 0 56.0757 0H26.1471C24.0842 0 22.406 1.68225 22.406 3.75V5.75H18.6649C16.6021 5.75 14.9238 7.43225 14.9238 9.5C14.9238 17.6321 18.72 23.6084 24.8786 25.2722C26.9898 29.9304 30.3278 33.8903 34.5647 36.7516C35.5407 37.4108 36.1233 38.5391 36.1233 39.77V46.6574C35.2684 46.8771 34.4842 47.3226 33.8436 47.9649C32.9012 48.9096 32.3822 50.165 32.3822 51.5H31.1352C29.0723 51.5 27.3941 53.1823 27.3941 55.25V62.75C27.3941 63.4404 27.9525 64 28.6411 64H53.5816C54.2702 64 54.8287 63.4404 54.8287 62.75V55.25C54.8287 53.1823 53.1504 51.5 51.0876 51.5H49.8406C49.8406 49.1745 48.2485 47.2146 46.0995 46.6582V39.7762C46.0995 38.5414 46.6825 37.4109 47.6593 36.7518C51.8965 33.8901 55.2346 29.9301 57.3457 25.2719C63.5034 23.6074 67.2989 17.6315 67.2989 9.5C67.2989 7.43225 65.6207 5.75 63.5578 5.75ZM17.4179 9.5C17.4179 8.81075 17.9773 8.25 18.6649 8.25H22.406V13.8375C22.406 16.6869 22.8366 19.4771 23.6867 22.1642C19.7183 20.1939 17.4179 15.6432 17.4179 9.5ZM51.0876 54C51.7752 54 52.3346 54.5607 52.3346 55.25V61.5H29.8881V55.25C29.8881 54.5607 30.4476 54 31.1352 54H51.0876ZM57.3227 13.8375C57.3227 17.252 56.6446 20.5745 55.3068 23.7125C53.4038 28.1771 50.2775 31.969 46.2661 34.6781C44.6001 35.8022 43.6054 37.7081 43.6054 39.7762V47.75C43.6054 48.4404 44.1638 49 44.8525 49C46.2277 49 47.3465 50.1215 47.3465 51.5H34.8762C34.8762 50.8327 35.1359 50.2051 35.6071 49.7326C36.0553 49.2834 36.6435 49.0261 37.2725 49.0019C37.3048 49.0044 37.3374 49.0056 37.3702 49.0056C38.0588 49.0056 38.6172 48.4404 38.6172 47.75V39.77C38.6172 37.7062 37.6232 35.8029 35.9583 34.6784C31.9465 31.9691 28.8202 28.1772 26.917 23.7122C25.5787 20.5754 24.9 17.253 24.9 13.8375V3.75C24.9 3.06075 25.4595 2.5 26.1471 2.5H56.0757C56.7633 2.5 57.3227 3.06075 57.3227 3.75V13.8375ZM58.5366 22.164C59.3863 19.4762 59.8168 16.6861 59.8168 13.8375V8.25H63.5578C64.2455 8.25 64.8049 8.81075 64.8049 9.5C64.8049 15.6429 62.5047 20.1934 58.5366 22.164Z"
                  fill="#D01818"
                />
                <path
                  d="M50.9806 14.7698C50.834 14.3172 50.4437 13.9873 49.9738 13.9191L44.5922 13.1361L42.185 8.24668C41.9749 7.81993 41.5414 7.5498 41.0667 7.5498C40.592 7.5498 40.1585 7.81993 39.9484 8.24668L37.5412 13.1361L32.1597 13.9191C31.6898 13.9874 31.2996 14.3173 31.1528 14.7698C31.0062 15.2224 31.1285 15.7192 31.4685 16.0513L35.3634 19.8563L34.4441 25.2297C34.3639 25.6987 34.5562 26.1726 34.9403 26.4523C35.3244 26.7319 35.8336 26.7688 36.2536 26.5474L41.0668 24.0108L45.8812 26.5474C46.304 26.7702 46.8129 26.7302 47.1945 26.4522C47.5784 26.1724 47.7707 25.6984 47.6904 25.2294L46.7701 19.8562L50.6649 16.0513C51.005 15.7192 51.1272 15.2224 50.9806 14.7698ZM44.5597 18.5246C44.2658 18.8118 44.1315 19.2257 44.2011 19.6313L44.8048 23.1559L41.6469 21.4921C41.2834 21.3007 40.8495 21.3007 40.4865 21.4922L37.3296 23.1559L37.9327 19.6311C38.002 19.2254 37.8678 18.8117 37.5739 18.5246L35.0195 16.0291L38.5487 15.5156C38.955 15.4564 39.3062 15.2007 39.4878 14.8317L41.0668 11.6243L42.6458 14.8317C42.8275 15.2007 43.1787 15.4564 43.585 15.5156L47.1143 16.0291L44.5597 18.5246Z"
                  fill="#D01818"
                />
                <path
                  d="M41.1121 56.5H36.124C35.4354 56.5 34.877 57.0596 34.877 57.75C34.877 58.4404 35.4354 59 36.124 59H41.1121C41.8007 59 42.3591 58.4404 42.3591 57.75C42.3591 57.0596 41.8007 56.5 41.1121 56.5Z"
                  fill="#D01818"
                />
                <path
                  d="M60.3184 60.2498H61.0136C60.6594 60.1794 60.4212 60.1724 60.3184 60.2498Z"
                  fill="#D01818"
                />
              </svg>
              <div className="inner_box_num">1200</div>
              <div className="inner_box_desc">Award achievment</div>
            </div>
            <div className="inner_box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="55"
                viewBox="0 0 73 67"
                fill="none"
              >
                <path
                  d="M62.3784 39.1939H9.32368C9.04669 39.1939 8.78105 39.0836 8.58519 38.8873C8.38933 38.691 8.2793 38.4247 8.2793 38.1471V2.09375C8.2793 1.8161 8.38933 1.54982 8.58519 1.3535C8.78105 1.15717 9.04669 1.04688 9.32368 1.04688H62.3784C62.6554 1.04688 62.9211 1.15717 63.1169 1.3535C63.3128 1.54982 63.4228 1.8161 63.4228 2.09375V38.1471C63.4228 38.4247 63.3128 38.691 63.1169 38.8873C62.9211 39.0836 62.6554 39.1939 62.3784 39.1939ZM10.3743 37.1002H61.334V3.14062H10.3681L10.3743 37.1002Z"
                  fill="#D01818"
                />
                <path
                  d="M59.4175 36.4314H12.2866C12.0096 36.4314 11.7439 36.3211 11.5481 36.1248C11.3522 35.9284 11.2422 35.6621 11.2422 35.3845V4.85449C11.2422 4.57684 11.3522 4.31057 11.5481 4.11424C11.7439 3.91791 12.0096 3.80762 12.2866 3.80762H59.4175C59.6945 3.80762 59.9602 3.91791 60.156 4.11424C60.3519 4.31057 60.4619 4.57684 60.4619 4.85449V35.3845C60.4619 35.6621 60.3519 35.9284 60.156 36.1248C59.9602 36.3211 59.6945 36.4314 59.4175 36.4314ZM13.331 34.3376H58.3731V5.90137H13.331V34.3376Z"
                  fill="#D01818"
                />
                <path
                  d="M12.2845 25.0326C12.1013 25.0322 11.9215 24.9836 11.763 24.8916C11.6045 24.7995 11.473 24.6673 11.3816 24.5083C11.2902 24.3492 11.2421 24.1688 11.2422 23.9852C11.2423 23.8016 11.2905 23.6213 11.3821 23.4623L22.4954 4.32753C22.5627 4.2055 22.6536 4.09817 22.7629 4.01185C22.8721 3.92552 22.9975 3.86195 23.1316 3.82485C23.2657 3.78775 23.4058 3.77787 23.5437 3.7958C23.6817 3.81373 23.8147 3.85911 23.9349 3.92926C24.0551 3.99942 24.1601 4.09294 24.2438 4.20433C24.3275 4.31573 24.3881 4.44276 24.4221 4.57796C24.4561 4.71317 24.4629 4.85382 24.4419 4.99167C24.421 5.12952 24.3727 5.26178 24.3001 5.38069L13.1878 24.5123C13.0959 24.6707 12.964 24.8022 12.8055 24.8935C12.647 24.9848 12.4673 25.0327 12.2845 25.0326Z"
                  fill="#D01818"
                />
                <path
                  d="M20.2375 36.4316C19.9615 36.4316 19.6966 36.322 19.5009 36.1268C19.3052 35.9316 19.1945 35.6667 19.1932 35.3899L19.0762 10.4984C19.0755 10.3609 19.1018 10.2247 19.1537 10.0974C19.2055 9.9701 19.2819 9.85431 19.3784 9.75661C19.4749 9.65891 19.5896 9.58122 19.7161 9.52798C19.8425 9.47473 19.9782 9.44697 20.1153 9.44629C20.3923 9.44629 20.658 9.55658 20.8538 9.75291C21.0497 9.94924 21.1597 10.2155 21.1597 10.4932L21.2819 35.3847C21.2819 35.6624 21.1719 35.9286 20.976 36.125C20.7802 36.3213 20.5145 36.4316 20.2375 36.4316Z"
                  fill="#D01818"
                />
                <path
                  d="M33.2732 36.4312C33.1339 36.4315 32.9961 36.4038 32.8676 36.3499C32.7392 36.296 32.6229 36.2168 32.5254 36.1172L19.4894 22.7381C19.3934 22.6397 19.3177 22.5232 19.2666 22.3955C19.2154 22.2677 19.1899 22.1311 19.1915 21.9935C19.193 21.8558 19.2216 21.7198 19.2756 21.5932C19.3296 21.4666 19.4079 21.352 19.5061 21.2557C19.6043 21.1595 19.7205 21.0836 19.8479 21.0324C19.9754 20.9811 20.1117 20.9555 20.249 20.9571C20.3863 20.9587 20.522 20.9873 20.6483 21.0414C20.7745 21.0955 20.889 21.174 20.985 21.2725L34.021 34.6515C34.165 34.7989 34.2625 34.9856 34.301 35.1883C34.3396 35.391 34.3175 35.6006 34.2376 35.7907C34.1577 35.9809 34.0236 36.1431 33.852 36.2571C33.6804 36.371 33.479 36.4316 33.2732 36.4312Z"
                  fill="#D01818"
                />
                <path
                  d="M26.5375 29.5216C26.3614 29.5211 26.1884 29.476 26.0343 29.3905C25.8803 29.3049 25.7504 29.1818 25.6566 29.0324C25.5628 28.883 25.5082 28.7123 25.4979 28.5361C25.4876 28.3599 25.5219 28.1839 25.5976 28.0246L36.8456 4.40292C36.8984 4.26953 36.978 4.14845 37.0795 4.0472C37.1809 3.94595 37.302 3.86669 37.4353 3.81434C37.5686 3.76198 37.7113 3.73766 37.8543 3.74288C37.9974 3.7481 38.1379 3.78274 38.267 3.84467C38.3962 3.90659 38.5112 3.99447 38.6051 4.10284C38.6989 4.21122 38.7695 4.33777 38.8125 4.47465C38.8555 4.61153 38.87 4.75581 38.855 4.89853C38.84 5.04125 38.796 5.17936 38.7255 5.30428L27.4806 28.926C27.3956 29.1044 27.2619 29.255 27.095 29.3605C26.9281 29.4659 26.7348 29.5218 26.5375 29.5216Z"
                  fill="#D01818"
                />
                <path
                  d="M40.2133 36.4311C40.0135 36.4313 39.8178 36.374 39.6494 36.266C39.4811 36.158 39.3472 36.0039 39.2637 35.8219C39.1801 35.64 39.1504 35.4378 39.178 35.2394C39.2056 35.041 39.2895 34.8547 39.4196 34.7027L58.6216 12.2179C58.7095 12.1094 58.8182 12.0197 58.9412 11.954C59.0643 11.8883 59.1992 11.8479 59.338 11.8353C59.4769 11.8227 59.6168 11.8381 59.7496 11.8806C59.8824 11.923 60.0054 11.9917 60.1114 12.0826C60.2173 12.1734 60.304 12.2846 60.3663 12.4096C60.4287 12.5346 60.4655 12.6708 60.4745 12.8103C60.4835 12.9497 60.4646 13.0896 60.4188 13.2216C60.373 13.3535 60.3014 13.475 60.208 13.5788L41.006 36.0678C40.9078 36.182 40.7861 36.2736 40.6493 36.3363C40.5125 36.399 40.3638 36.4313 40.2133 36.4311Z"
                  fill="#D01818"
                />
                <path
                  d="M52.3049 22.2732C52.0989 22.2736 51.8975 22.2124 51.7264 22.0973L34.8063 10.7827C34.5934 10.6226 34.4495 10.3871 34.404 10.1243C34.3586 9.86143 34.4149 9.5911 34.5615 9.36849C34.7081 9.14588 34.934 8.98777 35.193 8.92646C35.452 8.86516 35.7246 8.90527 35.9551 9.03861L52.8814 20.3553C53.068 20.48 53.2096 20.6616 53.2852 20.8732C53.3609 21.0849 53.3665 21.3153 53.3014 21.5303C53.2363 21.7454 53.1038 21.9338 52.9236 22.0676C52.7434 22.2014 52.525 22.2735 52.3008 22.2732H52.3049Z"
                  fill="#D01818"
                />
                <path
                  d="M29.9909 22.2732C29.7308 22.2725 29.4803 22.1746 29.2885 21.9986C29.0966 21.8226 28.9772 21.5811 28.9535 21.3215C28.9299 21.0619 29.0038 20.8028 29.1607 20.5948C29.3176 20.3869 29.5462 20.2451 29.8019 20.1973L47.2713 16.952C47.5437 16.9016 47.8249 16.9617 48.0531 17.1192C48.2813 17.2766 48.4377 17.5185 48.488 17.7916C48.5383 18.0646 48.4783 18.3465 48.3212 18.5752C48.1641 18.804 47.9228 18.9608 47.6504 19.0112L30.181 22.2565C30.1182 22.2677 30.0546 22.2733 29.9909 22.2732Z"
                  fill="#D01818"
                />
                <path
                  d="M8.78995 49.9532C7.93062 49.9532 7.09059 49.6977 6.37609 49.2191C5.6616 48.7406 5.10474 48.0603 4.77593 47.2645C4.44713 46.4687 4.36115 45.593 4.52887 44.7482C4.6966 43.9033 5.11049 43.1274 5.7182 42.5183C6.32591 41.9093 7.10015 41.4946 7.943 41.3267C8.78584 41.1588 9.65944 41.2452 10.4533 41.575C11.2472 41.9048 11.9256 42.4631 12.4029 43.1794C12.8802 43.8957 13.1348 44.7378 13.1346 45.5992C13.1332 46.7537 12.675 47.8605 11.8605 48.6768C11.046 49.493 9.9417 49.9521 8.78995 49.9532ZM8.78995 43.3369C8.34383 43.3369 7.90772 43.4695 7.53677 43.7179C7.16581 43.9664 6.87667 44.3195 6.7059 44.7326C6.53513 45.1457 6.49039 45.6003 6.57735 46.0389C6.66431 46.4775 6.87906 46.8805 7.19444 47.1967C7.50983 47.513 7.91169 47.7285 8.34922 47.8158C8.78674 47.9032 9.24029 47.8586 9.65251 47.6876C10.0647 47.5166 10.4171 47.2269 10.6651 46.8552C10.9131 46.4835 11.0456 46.0464 11.0458 45.5992C11.0444 45.0002 10.8062 44.4262 10.3834 44.0029C9.96053 43.5796 9.38754 43.3417 8.78995 43.3411V43.3369Z"
                  fill="#D01818"
                />
                <path
                  d="M13.0621 65.953H4.51899C4.24201 65.953 3.97636 65.8427 3.7805 65.6463C3.58464 65.45 3.47461 65.1837 3.47461 64.9061V61.7655C3.47461 61.4878 3.58464 61.2215 3.7805 61.0252C3.97636 60.8289 4.24201 60.7186 4.51899 60.7186H5.40672V47.9258C5.40672 47.6481 5.51675 47.3819 5.71261 47.1855C5.90847 46.9892 6.17412 46.8789 6.4511 46.8789C6.72809 46.8789 6.99374 46.9892 7.1896 47.1855C7.38546 47.3819 7.49549 47.6481 7.49549 47.9258V61.7655C7.49549 62.0431 7.38546 62.3094 7.1896 62.5057C6.99374 62.702 6.72809 62.8123 6.4511 62.8123H5.56338V63.8592H12.0177V62.8123H11.1299C10.853 62.8123 10.5873 62.702 10.3915 62.5057C10.1956 62.3094 10.0856 62.0431 10.0856 61.7655V47.9258C10.0856 47.6481 10.1956 47.3819 10.3915 47.1855C10.5873 46.9892 10.853 46.8789 11.1299 46.8789C11.4069 46.8789 11.6726 46.9892 11.8684 47.1855C12.0643 47.3819 12.1743 47.6481 12.1743 47.9258V60.7186H13.0621C13.339 60.7186 13.6047 60.8289 13.8005 61.0252C13.9964 61.2215 14.1064 61.4878 14.1064 61.7655V64.9061C14.1064 65.1837 13.9964 65.45 13.8005 65.6463C13.6047 65.8427 13.339 65.953 13.0621 65.953Z"
                  fill="#D01818"
                />
                <path
                  d="M62.9101 49.9532C62.0508 49.9534 61.2106 49.6981 60.496 49.2197C59.7814 48.7412 59.2243 48.0611 58.8954 47.2652C58.5664 46.4694 58.4803 45.5937 58.6479 44.7488C58.8155 43.9039 59.2293 43.1278 59.837 42.5187C60.4446 41.9096 61.2189 41.4948 62.0618 41.3268C62.9046 41.1588 63.7783 41.2451 64.5722 41.5749C65.3661 41.9046 66.0447 42.463 66.522 43.1793C66.9993 43.8956 67.2539 44.7378 67.2537 45.5992C67.2524 46.7535 66.7943 47.8602 65.98 48.6764C65.1657 49.4926 64.0617 49.9518 62.9101 49.9532ZM62.9101 43.3369C62.464 43.3367 62.0278 43.4691 61.6567 43.7174C61.2856 43.9657 60.9963 44.3187 60.8253 44.7319C60.6544 45.145 60.6095 45.5996 60.6964 46.0383C60.7832 46.477 60.9979 46.88 61.3132 47.1964C61.6286 47.5128 62.0304 47.7283 62.468 47.8158C62.9055 47.9032 63.3591 47.8586 63.7714 47.6877C64.1837 47.5167 64.5362 47.2271 64.7842 46.8553C65.0323 46.4836 65.1648 46.0464 65.165 45.5992C65.1633 45.0005 64.9252 44.4268 64.5026 44.0036C64.08 43.5804 63.5075 43.3422 62.9101 43.3411V43.3369Z"
                  fill="#D01818"
                />
                <path
                  d="M67.1831 65.953H58.6401C58.3631 65.953 58.0975 65.8427 57.9016 65.6463C57.7057 65.45 57.5957 65.1837 57.5957 64.9061V61.7655C57.5957 61.4878 57.7057 61.2215 57.9016 61.0252C58.0975 60.8289 58.3631 60.7186 58.6401 60.7186H59.5278V47.9258C59.5278 47.6481 59.6378 47.3819 59.8337 47.1855C60.0296 46.9892 60.2952 46.8789 60.5722 46.8789C60.8492 46.8789 61.1148 46.9892 61.3107 47.1855C61.5065 47.3819 61.6166 47.6481 61.6166 47.9258V61.7655C61.6166 62.0431 61.5065 62.3094 61.3107 62.5057C61.1148 62.702 60.8492 62.8123 60.5722 62.8123H59.6845V63.8592H66.1388V62.8123H65.251C64.9741 62.8123 64.7084 62.702 64.5125 62.5057C64.3167 62.3094 64.2067 62.0431 64.2067 61.7655V47.9258C64.2067 47.6481 64.3167 47.3819 64.5125 47.1855C64.7084 46.9892 64.9741 46.8789 65.251 46.8789C65.528 46.8789 65.7937 46.9892 65.9895 47.1855C66.1854 47.3819 66.2954 47.6481 66.2954 47.9258V60.7186H67.1831C67.4601 60.7186 67.7258 60.8289 67.9216 61.0252C68.1175 61.2215 68.2275 61.4878 68.2275 61.7655V64.9061C68.2275 65.1837 68.1175 65.45 67.9216 65.6463C67.7258 65.8427 67.4601 65.953 67.1831 65.953Z"
                  fill="#D01818"
                />
                <path
                  d="M35.8515 49.9532C34.9922 49.9534 34.152 49.6981 33.4374 49.2197C32.7228 48.7412 32.1657 48.0611 31.8368 47.2652C31.5078 46.4694 31.4217 45.5937 31.5893 44.7488C31.7569 43.9039 32.1707 43.1278 32.7784 42.5187C33.386 41.9096 34.1603 41.4948 35.0032 41.3268C35.846 41.1588 36.7197 41.2451 37.5136 41.5749C38.3075 41.9046 38.9861 42.463 39.4634 43.1793C39.9407 43.8956 40.1953 44.7378 40.1951 45.5992C40.1938 46.7535 39.7357 47.8602 38.9214 48.6764C38.1071 49.4926 37.0031 49.9518 35.8515 49.9532ZM35.8515 43.3369C35.4054 43.3367 34.9692 43.4691 34.5981 43.7174C34.227 43.9657 33.9377 44.3187 33.7667 44.7319C33.5958 45.145 33.5509 45.5996 33.6378 46.0383C33.7246 46.477 33.9393 46.88 34.2546 47.1964C34.57 47.5128 34.9718 47.7283 35.4094 47.8158C35.8469 47.9032 36.3005 47.8586 36.7128 47.6877C37.1251 47.5167 37.4776 47.2271 37.7256 46.8553C37.9737 46.4836 38.1062 46.0464 38.1064 45.5992C38.105 45.0004 37.8669 44.4265 37.4443 44.0033C37.0217 43.58 36.4489 43.3419 35.8515 43.3411V43.3369Z"
                  fill="#D01818"
                />
                <path
                  d="M40.1226 65.953H31.5795C31.3026 65.953 31.0369 65.8427 30.841 65.6463C30.6452 65.45 30.5352 65.1837 30.5352 64.9061V61.7655C30.5352 61.4878 30.6452 61.2215 30.841 61.0252C31.0369 60.8289 31.3026 60.7186 31.5795 60.7186H32.4673V47.9258C32.4673 47.6481 32.5773 47.3819 32.7732 47.1855C32.969 46.9892 33.2347 46.8789 33.5117 46.8789C33.7886 46.8789 34.0543 46.9892 34.2501 47.1855C34.446 47.3819 34.556 47.6481 34.556 47.9258V61.7655C34.556 62.0431 34.446 62.3094 34.2501 62.5057C34.0543 62.702 33.7886 62.8123 33.5117 62.8123H32.6239V63.8592H39.0782V62.8123H38.1905C37.9135 62.8123 37.6479 62.702 37.452 62.5057C37.2561 62.3094 37.1461 62.0431 37.1461 61.7655V47.9258C37.1461 47.6481 37.2561 47.3819 37.452 47.1855C37.6479 46.9892 37.9135 46.8789 38.1905 46.8789C38.4675 46.8789 38.7331 46.9892 38.929 47.1855C39.1248 47.3819 39.2349 47.6481 39.2349 47.9258V60.7186H40.1226C40.3996 60.7186 40.6652 60.8289 40.8611 61.0252C41.057 61.2215 41.167 61.4878 41.167 61.7655V64.9061C41.167 65.1837 41.057 65.45 40.8611 65.6463C40.6652 65.8427 40.3996 65.953 40.1226 65.953Z"
                  fill="#D01818"
                />
                <path
                  d="M23.1728 49.3578C19.1526 49.2883 15.1987 48.3193 11.6 46.5218C11.4789 46.4571 11.3718 46.3692 11.2847 46.263C11.1975 46.1568 11.1321 46.0345 11.0922 45.9029C11.0522 45.7714 11.0385 45.6333 11.0518 45.4964C11.0651 45.3596 11.1052 45.2267 11.1697 45.1054C11.2342 44.9841 11.3219 44.8767 11.4279 44.7893C11.5338 44.702 11.6559 44.6364 11.7871 44.5964C11.9183 44.5563 12.0561 44.5426 12.1926 44.5559C12.3291 44.5692 12.4617 44.6094 12.5827 44.6741C12.6872 44.7317 23.4945 50.3617 31.9749 44.7254C32.2057 44.5718 32.488 44.5165 32.7596 44.5716C33.0311 44.6266 33.2697 44.7876 33.4229 45.019C33.5761 45.2504 33.6313 45.5334 33.5763 45.8056C33.5214 46.0778 33.3608 46.317 33.13 46.4705C30.1717 48.4052 26.7046 49.4105 23.1728 49.3578Z"
                  fill="#D01818"
                />
                <path
                  d="M50.233 49.6904C46.1576 49.5961 42.1678 48.4997 38.6142 46.4975C38.3882 46.3496 38.228 46.1197 38.1674 45.8561C38.1067 45.5924 38.1502 45.3155 38.2888 45.0833C38.4273 44.8511 38.6502 44.6817 38.9107 44.6106C39.1711 44.5396 39.4489 44.5723 39.6858 44.7021C39.7902 44.7659 50.5474 51.0493 58.9871 44.7607C59.0971 44.6786 59.2222 44.6191 59.3553 44.5855C59.4883 44.5519 59.6266 44.5448 59.7623 44.5648C59.898 44.5848 60.0285 44.6314 60.1463 44.7019C60.264 44.7724 60.3668 44.8655 60.4487 44.9758C60.5306 45.0861 60.59 45.2116 60.6235 45.3449C60.657 45.4782 60.664 45.6169 60.6441 45.7529C60.6241 45.889 60.5777 46.0198 60.5073 46.1378C60.437 46.2558 60.3441 46.3588 60.2341 46.4409C57.3456 48.5931 53.8318 49.7348 50.233 49.6904Z"
                  fill="#D01818"
                />
              </svg>
              <div className="inner_box_num">60+</div>
              <div className="inner_box_desc">Total Showroom</div>
            </div>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
}
